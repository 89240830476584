










































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {User} from '@/models/User';
import {Company} from '@/models/Company';
import Filters from '@/utils/Filters';

Vue.use(Filters);
const AuthStore = namespace('AuthStore');
const LoadingStore = namespace('LoadingStore');

@Component({
    name: 'PanelCustomerFinace',
})
export default class PanelCustomerFinace extends Vue {

    @AuthStore.State
    public user!: User;

    @AuthStore.State
    public currentCompany!: Company;

    @LoadingStore.Mutation
    public stopLoading!: (calledLoad: string) => Promise<void>;

    @LoadingStore.Mutation
    public startLoading!: (calledLoad: string) => Promise<void>;

    /*
    * parâmetros a serem distribuidos para controle de páginação
    * e exibição de linhas da tabela
    */
    public serverParams: any = {
        columnFilters: {},
        sort: {
            field: 'id',
            type: 'desc',
        },
        page: 1,
        perPage: 100000,
    };

    public totalAberto: number = 0;
    public totalVencido: number = 0;
    public totalPago: number = 0;
    public tituloVencer15dias: number = 0;
    public tituloVencerNaSemana: number = 0;

    public onAccessRequest(): void {
        this.$router.push('finance');
    }

    /*
    * carrega a página a partir dos dados coletados pelo ponto de acesso
    */
    @Watch('currentCompany')
    public loadItems(): void {
        if (!this.currentCompany.id) {
            return;
        }
        this.startLoading('finance');
        this.$axios
            .post(
                `/financeiro/dash/${this.currentCompany.id}`,
                this.serverParams.columnFilters,
                {
                    params: Object.assign({}, this.serverParams),
                },
            )
            .then((response) => {
                this.stopLoading('finance');
                this.totalAberto = response.data.tituloAberto;
                this.totalVencido = response.data.tituloVencido;
                this.totalPago = response.data.tituloPago;
                this.tituloVencerNaSemana = (response.data.tituloVencerNaSemana !== undefined
                    ? response.data.tituloVencerNaSemana : 0);
            })
            .catch((error) => {
                this.stopLoading('finance');
            });
    }

    /*
    * monta a página
    */
    public mounted(): void {
        this.$nextTick(() => {
            this.loadItems();
        });
    }
}
