
























































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {User} from '@/models/User';
import Filters from '@/utils/Filters';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';
import WarningMessage from '@/components/commons/WarningMessage.vue';
import SelectTracker from '@/components/searchNfe/SelectTracker.vue';
import {Authority} from '@/models/Authority';
import {Company} from '@/models/Company';

Vue.use(Filters);

const AuthStore = namespace('AuthStore');

@Component({
    name: 'PanelCustomerSearch',
    components: {WarningMessage, ErrorMessage, SelectTracker},
})

export default class PanelCustomerSearch extends Vue {

    @AuthStore.State
    public user!: User;

    @AuthStore.State
    public authorityList!: Authority[];

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    @AuthStore.Action
    public updateAuthorities!: () => Promise<void>;

    @AuthStore.State
    public warningMessage!: string;

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.Mutation
    public setWarningMessage!: (newWarningMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    /*
    * largura do label
    */
    public formLabelWidth: any = '48px';

    public nfeNumero: string = '';

    public chave: string = '';

    public etapa: string = '';

    /*
    * Tracker limpa o input
    */
    public reset(): void {
        this.nfeNumero = '';
    }

}
