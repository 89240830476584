










import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'WarningMessage',
})
export default class WarningMessage extends Vue {
  @AuthStore.State
  public warningMessage!: string;
}
