

































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {AppInfo} from '@/models/AppInfo';
import {Authority} from '@/models/Authority';
import {Company} from '@/models/Company';
import CompanyCarousel from '@/components/company/CompanyCarousel.vue';
import PanelCustomerAccessRequest from '@/components/orders/PanelCustomerAccessRequest.vue';
import PanelCustomerFreight from '@/components/freight/PanelCustomerFreight.vue';
import PanelCustomerFinace from '@/components/finance/PanelCustomerFinace.vue';
import PanelCustomerCollect from '@/components/collection/PanelCustomerCollect.vue';
import PanelCustomerSearch from '@/components/searchNfe/PanelCustomerSearch.vue';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'Dashboard',
    components: {
        CompanyCarousel, PanelCustomerAccessRequest, PanelCustomerFreight, PanelCustomerFinace,
        PanelCustomerCollect, PanelCustomerSearch},
})
export default class Dashboard extends Vue {

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public authorityList!: Authority[];

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.Action
    public updateAuthorities!: () => Promise<void>;

    @AuthStore.Getter
    public isAuthenticated!: boolean;

    public orders() {
        this.$router.replace({ path: '/orders/' });
    }

    public hasAuthOnCurrentCompany(auth: string, currentCompany: Company): boolean {
        return this.authorityList.some(
            (authority) => authority.empresaLoginId === currentCompany.id && authority.auth === auth,
        );
    }

    public isShowWelcome(): boolean {
        const existsAuth = this.authorityList.some(
            (authority) => authority.auth !== null,
        );
        return existsAuth === false && this.isAuthenticated;
    }

    public created(): void {
        if (this.isAuthenticated) {
            this.updateAuthorities();
        }
    }

    public hasPermission(param: string): boolean {
        return this.hasAuthOnCurrentCompany(param, this.currentCompany);
    }
}
