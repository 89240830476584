









































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {User} from '@/models/User';
import {Company} from '@/models/Company';
import Filters from '@/utils/Filters';
import {EtapaRastreamento, EtapaRastreamentoLookup} from '@/enums/EtapaRastreamento';

Vue.use(Filters);

const AuthStore = namespace('AuthStore');
const LoadingStore = namespace('LoadingStore');

interface OcorrenciaCteEtapa {
    countCtes: number;
    etapa: number;
}

@Component({
    name: 'PanelCustomerFreight',
})
export default class PanelCustomerFreight extends Vue {

    @AuthStore.State
    public user!: User;

    @AuthStore.State
    public currentCompany!: Company;

    @LoadingStore.Mutation
    public stopLoading!: (calledLoad: string) => Promise<void>;

    @LoadingStore.Mutation
    public startLoading!: (calledLoad: string) => Promise<void>;

    /*
    * parâmetros a serem distribuidos para controle de páginação
    * e exibição de linhas da tabela
    */
    public serverParams: any = {
        columnFilters: {},
        sort: {
            field: 'id',
            type: 'desc',
        },
        page: 1,
        perPage: 100000,
    };

    public percentage: number = 0;
    public contaCtes: number = 0;
    public pendentesEntrega: number = 0;
    public emitidosNaSemana: number = 0;
    public ctePesoTotal: number = 0;
    public vprestValTotPrest: number = 0;
    public ocorrenciaCteEtapa: OcorrenciaCteEtapa[] = [];
    public progressEtapaList: any = [];

    public getStage(currentStage) {
        return EtapaRastreamentoLookup.lookup[currentStage] || EtapaRastreamento.DEFAULT;
    }

    public onAccessRequest(): void {
        this.$router.push('freight');
    }

    @Watch('currentCompany')
    public loadItems(): void {
        if (!this.currentCompany.id) {
            return;
        }
        this.startLoading('freight');
        this.$axios
            .post(
                `/cte/dash/${this.currentCompany.id}`,
                this.serverParams.columnFilters,
                {
                    params: Object.assign({}, this.serverParams),
                },
            )
            .then((response) => {
                this.stopLoading('freight');
                this.contaCtes = 0;
                this.ctePesoTotal = response.data.pesoBc;
                this.vprestValTotPrest = response.data.vprestValTotPrest;
                this.emitidosNaSemana = response.data.emitidosNaSemana;
                this.ocorrenciaCteEtapa = response.data.ocorrenciaCteList || [];
            })
            .catch((error) => {
                this.stopLoading('freight');
            })
            .finally(() => {
                this.formatProgress();
            });
    }

    public formatProgress() {
        let totalFretes = 0;
        let pendentes = 0;
        this.ocorrenciaCteEtapa.forEach( (oco) => {
            totalFretes += oco.countCtes;

            // conta os nao entregues
            if (this.getStage(oco.etapa).toString() !== EtapaRastreamento.ENTREGUE.toString()) {
                pendentes += oco.countCtes;
            }
        });

        /*this.ocorrenciaCteEtapa.forEach( (oco) => {
            this.progressEtapaList.push({
                description: this.getStage(oco.etapa).description,
                percentage: this.calcPercent(totalFretes, oco.countCtes),
                total: oco.countCtes,
                color: this.getStage(oco.etapa).color,
            });
        });*/

        this.contaCtes = totalFretes;
        this.pendentesEntrega = pendentes;
    }

    public calcPercent(totalFretes, itemProgress): number {
        return Math.round(itemProgress / totalFretes * 100);
    }

    /*
    * monta a página
    */
    public mounted(): void {
        this.$nextTick(() => {
            this.loadItems();
        });
    }
}
