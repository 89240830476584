



























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Company} from '@/models/Company';
import {FreigthListInfo} from '@/models/FreigthListInfo';
import Tracker from '@/components/freight/Tracker.vue';
import {Utils} from '@/utils/Utils';

const AuthStore = namespace('AuthStore');

export class SearchFormFilters {
    public nfeNumero: string = '';
}

@Component({
    name: 'SelectTracker',
    components: {Tracker},
})
export default class SelectTracker extends Vue {

    @AuthStore.State
    public currentCompany!: Company;

    @Prop()
    public nfeNumero!: string;

    @Prop()
    public tituloStatico!: string;

    /*
    * quantidade de registros
    */
    public totalRecords: number = 0;

    /*
    * parâmetros a serem distribuidos para controle de páginação
    * e exibição de linhas da tabela
    */
    public serverParams: any = {
        columnFilters: {},
        sort: {
            field: 'dataEmissao',
            type: 'asc',
        },
        page: 1,
        perPage: 50,
    };

    public loading: boolean = false;

    public showDialog: boolean = false;

    /*
   * propriedade pra filtro de busca
   */
    public formFilters: SearchFormFilters = new SearchFormFilters();

    /*
    * linhas da tebela
    */
    public rows: FreigthListInfo[] = [] as FreigthListInfo[];

    public tableRowClassName({row, rowIndex}) {
        if (rowIndex % 2 === 0) {
            return 'primary-table-row';
        } else {
            return 'secondary-table-row';
        }
        return '';
    }

    public formatString(str, len = 50, reduce = 47): string {
        return Utils.formatSizeStringRow(str, len, reduce);
    }

    public formatNfe(row): string {
        if (row.nfeResourceList != null && row.nfeResourceList.length) {
            const nfe = row.nfeResourceList.filter( (nfe) => {
                return nfe.numero.toString() === this.formFilters.nfeNumero;
            });

            if (nfe[0]) {
                return `${nfe[0].numero} - ${nfe[0].serie}`;
            }
        }
        return ``;
    }

    /*
    * Evento que aciona o reset()
    * para limpar o input do
    * PainelCustomerSearch
    */
    @Emit('on-close')
    public onClose(): void {
        this.loading = false;
        this.totalRecords = 0;
    }

    /*
    * carrega a página a partir dos dados coletados pelo ponto de acesso
    */
    public async loadItems(): Promise<void> {
        if (!this.currentCompany.id) {
            return;
        }
        if (this.formFilters.nfeNumero !== '' && this.formFilters.nfeNumero.length > 0) {
            this.applyFilters();
            await this.$axios
                .post(
                    `/cte/list/${this.currentCompany.id}`,
                    this.serverParams.columnFilters,
                    {
                        params: Object.assign({}, this.serverParams),
                    },
                ).then((response) => {
                    this.loading = false;
                    const data = response.data || {};
                    this.rows = data.resources || [];
                    this.totalRecords = data.recordsFiltered;
                    if (this.rows.length > 0) {
                        this.showDialog = true;
                    } else {
                        this.$toast.warning(`Nenhuma ocorrência encontrada com a NF-e informada:
                         ${this.formFilters.nfeNumero}`);
                    }
                }).catch((error) => {
                    Vue.$toast.info('Não foi possível carregar os dados no momento, tente novamente mais tarde!');
                    this.loading = false;
                    return;
                });
        } else {
            this.$toast.info(`Informe o número da NF-e`);
            this.loading = false;
            return;
        }
    }

    /*
    * dispara a busca e fecha formulário
    */
    public async search(): Promise<void> {
        this.formFilters.nfeNumero = this.nfeNumero;
        this.loading = true;
        await this.loadItems();
    }

    /*
    * aplicao dos filtros de busca por item da página
    */
    private applyFilters() {
        this.serverParams.columnFilters = Object.assign(
            {},
            {
                nfeNumero: this.formFilters.nfeNumero || null,
            },
        );
    }

}
