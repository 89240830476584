

























































import { Component, Vue } from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {User} from '@/models/User';
import {Company} from '@/models/Company';
import {StatusAccessRequest, StatusAccessRequestLookup} from '@/enums/StatusAccessRequest';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'PanelCustomerAccessRequest',
})
export default class PanelCustomerAccessRequest extends Vue {

  @AuthStore.State
  public user!: User;

  @AuthStore.State
  public currentCompany!: Company;

  @AuthStore.Mutation
  public setCurrentStatusFilter!: (newStatusFilter: StatusAccessRequest | undefined) => Promise<void>;

  public contaAprovados: number = 0;
  public contaPendentes: number = 0;
  public contaNegados: number = 0;
  public contaInvalidos: number = 0;
  public mostraInvalidos: boolean = false;

  public onAccessRequest(status: string): void {
    this.setCurrentStatusFilter(StatusAccessRequestLookup.getStatusByKey(status));
    this.$router.push('orders');
  }

  public async updateAccessCounters(): Promise<void> {
    if (!this.currentCompany.id) {
      return;
    }
    return this.$axios
      .get(`/acesso/${this.user.login}/${this.currentCompany.id}`)
      .then((responseList) => {
        const contaTotais = (array) => {
          array.forEach((element) => {
            if (element.status === 'DENIED') {
              this.contaNegados++;
            }
            if (element.status === 'INVALID') {
              this.contaInvalidos++;
            }
            if (element.status === 'PENDING') {
              this.contaPendentes++;
            }
            if (element.status === 'RELEASED') {
              this.contaAprovados++;
            }
          });
        };
        contaTotais(responseList.data);
      })
      .finally(() => {
        this.mostraInvalidos = (this.contaInvalidos > 0) ? true : false;
      });
  }

  public created(): void {
    setTimeout(() => this.updateAccessCounters(), 100);
  }
}
