











import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {User} from '@/models/User';
import {Company} from '@/models/Company';
import Filters from '@/utils/Filters';

Vue.use(Filters);

const AuthStore = namespace('AuthStore');

@Component({
    name: 'PanelCustomerCollect',
})
export default class PanelCustomerCollect extends Vue {

    @AuthStore.State
    public user!: User;

    @AuthStore.State
    public currentCompany!: Company;

    /*
    * parametros a serem distribuidos para controle de paginaçao
    * e exibi-lo de linhas da tabela
    */
    public serverParams: any = {
        columnFilters: {},
        sort: {
            field: 'id',
            type: 'desc',
        },
        page: 1,
        perPage: 100000,
    };

    public onAccessRequest(): void {
        this.$router.push('collection');
    }

    @Watch('currentCompany')
    public loadItems(): void {
        /*if (!this.currentCompany.id) {
            return;
        }
        this.$axios
            .post(
                `/coleta/dash/${this.currentCompany.id}`,
                this.serverParams.columnFilters,
                {
                    params: Object.assign({}, this.serverParams),
                },
            )
            .then((response) => {
            })
            .catch((error) => {
            });*/
    }

    /*
    * monta a pagina
    */
    public mounted(): void {
        this.$nextTick(() => {
            this.loadItems();
        });
    }
}
