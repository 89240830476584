

















import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Carousel} from '@/types';
import {Company} from '@/models/Company';
import {AppInfo} from '@/models/AppInfo';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'companyCarousel',
})
export default class CompanyCarousel extends Vue {

    public $refs!: {
        carousel: Carousel,
    };

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public currentCompany!: Company;

    @AuthStore.State
    public companyList!: Company[];

    @AuthStore.Action
    public updateCompany!: (companyId: number) => Promise<void>;

    private currentIndex: number = 0;

    @Watch('currentCompany')
    public onCurrentCompanyChange(newCompany: Company, oldCompany: Company) {
        if (!this.$refs.carousel) {
            return;
        }
        if (newCompany) {
            const newCompanyIndex = this.companyList.map((company) => company.id).indexOf(newCompany.id);
            if (newCompanyIndex !== this.currentIndex) {
                this.$refs.carousel.setActiveItem(newCompanyIndex);
            }
        } else {
            this.$refs.carousel.setActiveItem(0);
        }
    }

    public onChange(newIndex: number, oldIndex: number): void {
        if (newIndex !== this.currentIndex) {
            this.currentIndex = newIndex;
            this.updateCompany(this.companyList[this.currentIndex].id);
        }
    }

    public onAccessRequest(): void {
        this.$router.push('orders');
    }

    public created(): void {
        if (!this.info.dedicatedCompanyId) {
            if (this.currentCompany.id == null && this.companyList.length > 0) {
                this.currentCompany = this.companyList[0];
            }

            if (this.currentCompany.id != null) {
                this.currentIndex = this.companyList.map((company) => company.id).indexOf(this.currentCompany.id);
                if (this.currentIndex === -1) {
                    this.currentIndex = 0;
                }
            }
        }
    }

    public mounted(): void {
        if (this.$refs.carousel) {
            this.$refs.carousel.setActiveItem(this.currentIndex);
        }
    }

    public updated(): void {
        if (this.$refs.carousel) {
            this.$refs.carousel.setActiveItem(this.currentIndex);
        }
    }
}
